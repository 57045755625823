import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Icon } from 'react-icons-kit'
import { StyledLegend, StyledCollapse, StyledCollapseDiv } from './styles'

import { chevronUp } from 'react-icons-kit/feather/chevronUp'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'

const BasicCollapsibleSection = ({ label, children, errorColor, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
	const [error, setError] = useState(errorColor);

	useEffect(() => {
		setError(errorColor);
	}, [errorColor]);

  return (
    <>
      <StyledCollapse onClick={() => setIsOpen(!isOpen)}>
        <StyledLegend collapsable="true" errorColor={error}>{label}</StyledLegend>
        <Icon icon={isOpen ? chevronUp : chevronDown} />
      </StyledCollapse>

      <StyledCollapseDiv open={isOpen}>
        {children}
      </StyledCollapseDiv>
    </>
  )
}

BasicCollapsibleSection.defaultProps = {
};

BasicCollapsibleSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  errorColor: PropTypes.bool,
  props: PropTypes.object
}

export default BasicCollapsibleSection
