import { useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { BasicButton, BasicLabel, BasicModal } from '../index';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { zoom_in as IkonsZoomIn } from 'react-icons-kit/ikons/zoom_in'
import { zoom_out as IkonsZoomOut } from 'react-icons-kit/ikons/zoom_out'

import {
	StyledDropZone,
	StyledPreviewContainer,
	StyledPreviewButton,
	StyledPreviewImage,
	StyledPreviewFile, WrapperIconCropper, ContainerIconCropper, WrapperDropzone, StyledCropError,
} from './styles'
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { ic_clear as iconClear } from 'react-icons-kit/md/ic_clear';
import { ic_check } from 'react-icons-kit/md/ic_check';
import messages from './messages';
import { newEvent } from '../../utils';
import VariantUploadButton from './components/VariantUploadButton'
import VariantUploadDropzone from './components/VariantUploadDropzone'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'
import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from '../../store/manageUsers'
import { storeModule as AppModule } from '../../store/app'

const BasicImageCrop = ({ app, session, file, label, helpMessage, name, required, accept, variant, preview, onChange, aspectRatio, children, updateCrop, updateErrorCrop, error, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(file || '');
  const [urlValue, setUrlValue] = useState(file || '');

  const cropperRef = useRef();
  const inputUploadRef = useRef();

  const intl = useIntl()

  const handleOnCancelCrop = () => {
    handleOnResettingFile();
  };

  const handleOnClickButtonZoomIn = () => {
    cropperRef.current?.cropper.zoom(0.1);
  }

  const handleOnClickButtonZoomOut = () => {
    cropperRef.current?.cropper.zoom(-0.1);
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Escape') {
      handleOnResettingFile();
    }
  };

  const handleOnResettingFile = () => {
	  updateCrop(false)
	  updateErrorCrop(false)
    setIsModalOpen(false);
    setUrlValue(false);
    setUploadedFile('');
    onChange(newEvent(name, ''))
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOnAcceptCrop = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

      setUrlValue(croppedCanvas.toDataURL())

      croppedCanvas.toBlob(blob => {
        const fileObject = new File([blob], 'croppedImage.png', { type: 'image/png' });
        setUploadedFile(fileObject);
      });

	    updateCrop(false)
	    updateErrorCrop(false)
      onChange(newEvent(name, croppedCanvas.toDataURL()));
    }

    if(preview === 'modal') {
      toggleModal();
    }
  };

  const { getInputProps } = useDropzone({
    accept: accept,
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
	    updateCrop(true)
      if(preview === 'modal') {
        toggleModal();
      }

      const reader = new FileReader();

      reader.onload = () => {
        setUploadedFile(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  });

  return (
    <WrapperDropzone>
      <AvGroup ref={cropperRef}>
        <BasicLabel label={label} required={required} helpMessage={helpMessage} />

        <AvInput name={name} value={uploadedFile} type="hidden" />
      </AvGroup>

      {!uploadedFile ? (
        <StyledDropZone>
          {variant === 'button' && (
            <VariantUploadButton onClick={() => inputUploadRef.current?.click()} />
          )}

          {variant === 'dropzone' && (
            <VariantUploadDropzone onClick={() => inputUploadRef.current?.click()} />
          )}

          <input {...getInputProps()} ref={inputUploadRef} />
        </StyledDropZone>
      ) : (
        <>
          {!urlValue && preview === 'inline' && (
            <StyledPreviewContainer>
              <StyledPreviewImage>
                <Cropper
                  ref={cropperRef}
                  src={uploadedFile}
                  dragMode="move"
                  center={true}
                  cropBoxMovable={true}
                  cropBoxResizable={true}
                  aspectRatio={aspectRatio}
                />
              </StyledPreviewImage>

              <StyledPreviewButton>
                <BasicButton
                  outlined
                  color="green"
                  small
                  icon={<Icon icon={ic_check} />}
                  onClick={handleOnAcceptCrop}
                  round
                />
                <BasicButton
                  outlined
                  color="pink"
                  small
                  icon={<Icon icon={iconClear} />}
                  onClick={handleOnCancelCrop}
                  round
                />
                <BasicButton
                  outlined
                  color="gray"
                  small
                  icon={<Icon icon={IkonsZoomIn} />}
                  onClick={handleOnClickButtonZoomIn}
                  round
                />
                <BasicButton
                  outlined
                  color="gray"
                  small
                  icon={<Icon icon={IkonsZoomOut} />}
                  onClick={handleOnClickButtonZoomOut}
                  round
                />
              </StyledPreviewButton>
            </StyledPreviewContainer>
          )}

          {!urlValue && preview === 'modal' && isModalOpen && (
            <BasicModal
              title={intl.formatMessage(messages.title)}
              content={
                <>
                  <Cropper
                    ref={cropperRef}
                    src={uploadedFile}
                    dragMode="move"
                    center={true}
                    cropBoxMovable={true}
                    cropBoxResizable={true}
                    aspectRatio={aspectRatio}
                  />
                </>
              }
              actions={
                <>
                  <BasicButton
                    onClick={handleOnCancelCrop}
                    label={intl.formatMessage(messages.cancel)}
                    outlined
                  />
                  <BasicButton
                    onClick={handleOnAcceptCrop}
                    label={intl.formatMessage(messages.confirm)}
                    color="green"
                  />
                  <ContainerIconCropper>
                    <WrapperIconCropper onClick={handleOnClickButtonZoomIn}>
                      <Icon icon={IkonsZoomIn} />
                    </WrapperIconCropper>
                    <WrapperIconCropper onClick={handleOnClickButtonZoomOut}>
                      <Icon icon={IkonsZoomOut} />
                    </WrapperIconCropper>
                  </ContainerIconCropper>
                </>
              }
              isOpen={isModalOpen}
              toggle={handleOnCancelCrop}
              onKeyDown={handleOnKeyDown}
            />
          )}

          {uploadedFile && urlValue && (
            <StyledPreviewContainer>
              <StyledPreviewImage>
                <img src={urlValue} alt="cropped" {...(props?.previewImageInlineStyle && { style: props.previewImageInlineStyle })} />
              </StyledPreviewImage>

              <StyledPreviewButton>
                <StyledPreviewFile src={urlValue} />
                <BasicButton
                  outlined
                  color="pink"
                  small
                  icon={<Icon icon={iconClear} />}
                  onClick={handleOnCancelCrop}
                  round
                />
              </StyledPreviewButton>
            </StyledPreviewContainer>
          )}
        </>
      )}
	    {error && <StyledCropError>{intl.formatMessage(messages.error)}</StyledCropError>}
      {children}
    </WrapperDropzone>
  );
};

BasicImageCrop.defaultProps = {
  file: '',
  url: '',
  accept: 'image/jpeg, image/png, image/x-png, image/svg+xml, image/gif',
  variant: 'dropzone',
  aspectRatio: 1,
  preview: 'modal',
  onChange: () => {}
};

BasicImageCrop.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  app: PropTypes.object,
  session: PropTypes.object,
  favicon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  accept: PropTypes.string,
  variant: PropTypes.oneOf(['button', 'dropzone']),
  aspectRatio: PropTypes.number,
  preview: PropTypes.oneOf(['modal', 'inline']),
  onChange: PropTypes.func,
  updateCrop: PropTypes.func,
	updateErrorCrop: PropTypes.func,
	error:PropTypes.bool,
};

export default connectStore({ app: AppModule, session: SessionModule })(BasicImageCrop)
